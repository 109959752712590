import React, { useState } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import Icon from '../../../../components/cmp_icon';
import { CHECKBOX, FORM_TOGGLE } from '../../../../components/cmp_form/cmp_form';



export default function CRD_FEATURES({ var_organization, set_organization, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_error, set_error ] = useState(false);

    const feature_list = [
        { 
            feature: 'networks', 
            name: 'Affiliate network', 
            info: 'Establish a network of connected organizations to enable sharing of assignments, resources, and related items.',
            sub_features: [
                {
                    feature: 'network_invite',
                    name: 'Externally visible',
                    info: 'Make this organization visible to others, allowing them to invite it to join their affiliate network.'
                }
            ]
        },
        { 
            feature: 'assignments', 
            name: 'Assignments', 
            info: 'Assignments allow for the grouping of individuals, requirements, documents, and other related items.',
            sub_features: [
                {
                    feature: 'dailycheck',
                    name: 'Daily check',
                    info: 'Present a daily questionnaire that users are required to complete.'
                },
                { 
                    feature: 'r2w', 
                    name: 'Onsite', 
                    info: 'Enable individuals to declare their physical presence on a work site.' 
                },
                { 
                    feature: 'qraccess', 
                    name: 'QR access', 
                    info: 'Enable individuals to join an assignment by scanning a QR code.' 
                },
            ]
        },
        { 
            feature: 'credentials', 
            name: 'Credential visibility', 
            info: "Allow Admins to view an individual's credentials from either the worker's profile or an assignment.",
            sub_features: [
                { 
                    feature: 'manage_credentials', 
                    name: 'Manage credentials', 
                    info: "Allow Admins to activate or deactivate an individual's credentials."
                },
            ]
        },
        { feature: 'demoaccount', name: 'Demo organization', info: 'This is a demo organization.' },
        { feature: 'documents', name: 'Documents', info: 'Upload documents to be acknowledged by individuals.' },
        { feature: 'experimental', name: 'Experimental', info: 'This organization has experimental features.', read_only: true },
        { feature: 'guest', name: 'Guest access', info: "Allow individuals to join the organization as a 'Guest.'" },
        { feature: 'identity_verification', name: 'Identity verification', info: 'The organization can perform identity verifications.', read_only: true },
        { 
            feature: 'issuer', 
            name: 'Issuer', 
            info: 'This organization issues credentials',
            sub_features: [
                { 
                    feature: 'notification_email_expiry', 
                    name: 'Credential expiry email', 
                    info: <div style={{ flexDirection: 'column', gap: '1rem' }}><div>Send email notifications to credential holders as their credentials near expiration. Reminders are sent at 60 days, 30 days, 7 days, and 1 day before expiry.</div>This setting is manually configured on the credential footprint for each credential that requires a notification.</div> 
                },
                { 
                    feature: 'notification_email_issuance', 
                    name: 'Credential issuance email', 
                    info: <div style={{ flexDirection: 'column', gap: '1rem' }}><div>Send an email notification to the holder when a credential is issued to them.</div><div>This setting is manually configured on the credential footprint for each credential that requires a notification.</div></div> 
                },
                { 
                    feature: 'credit_tracking', 
                    name: 'Credit tracking credential', 
                    info: 'Enable the creation of credit tracking credentials.' 
                },
                { 
                    feature: 'credentials_ext', 
                    name: 'External visibility', 
                    info: 'The organization and its credentials are accessible to individuals outside of this organization.' 
                },
                { 
                    feature: 'verifiable_credentials', 
                    name: 'Verifiable credentials', 
                    info: 'The organization issues credentials as verifiable credentials.' 
                },
                { 
                    feature: 'proof_type', 
                    name: 'Verifiable credentials - Proof type', 
                    info: 'Enable the selection of the data proof type when creating a verifiable credential.' 
                },
            ]
        },
        { feature: 'mfa', name: 'MFA', info: 'Enable multifactor authentication for the organization.' },
        { feature: 'reports', name: 'Reports', info: 'Enable reports for the organization.' },
        { feature: 'requirements', name: 'Requirements', info: 'Monitor compliance of individuals with credential requirements and packages.' },
        { feature: 'vc-wallet', name: 'VC Wallet', info: 'Enable support for verifiable credentials in the cloud wallet.', read_only: true },
        { feature: 'verification_templates', name: 'Verification templates', info: 'The organization can create verification templates for VC verification.', read_only: true },
    ];



    //  event listeners ------------------------------------------------------------------------------------------------



    //  functions ------------------------------------------------------------------------------------------------------



    //  API calls ------------------------------------------------------------------------------------------------------



    //  event functions ------------------------------------------------------------------------------------------------

    function onChange(changed_feature) {
        let organization = { ...var_organization };
        organization[changed_feature] = organization[changed_feature] === 'YES' ? 'NO' : 'YES';
        if (organization[changed_feature] === 'NO') {
            // Disable sub features whose parents become disabled
            let sub_features = feature_list.find(item => item.feature === changed_feature)?.sub_features;
            sub_features?.forEach(sub_feature => {
                organization[sub_feature.feature] = 'NO';
            });
        }
        set_organization(organization);
    }

    function onClick_next() {
        // Make sure at least one feature is selected
        if (feature_list.filter(item => var_organization[item.feature] && var_organization[item.feature] === 'YES').length === 0) {
            set_error(true);
            return
        }
        set_error(false);
        // Make sure all fields are populated
        let organization = {...var_organization};
        const populate_missing_features = (features) => {
            features.forEach(feature => {
                !organization[feature.feature] && (organization[feature.feature] = 'NO');
                if (feature.sub_features) populate_missing_features(feature.sub_features);
            });
        }
        populate_missing_features(feature_list);
        set_organization(organization);
        onClick_nextstep();
    }

    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_form() {
        return (
            <>
            {var_error &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'Please select at least one feature.'}
                />
                }
                <Form className='form_features'>
                    {feature_list.map((feature, index) => {
                        return (<React.Fragment key={index}>
                            <FORM_TOGGLE
                                label={feature.name}
                                value={var_organization[feature.feature] ? var_organization[feature.feature] : 'NO'}
                                options={[{value: 'YES', text: 'Yes' }, { value: 'NO', text: 'No' }]}
                                onChange={() => onChange(feature.feature)}
                                disabled={feature.read_only}
                                description={feature.info}
                            />
                            {var_organization[feature.feature] === 'YES' && feature.sub_features &&
                                <div className='detailsgroup__subgroup rounded-lg'>
                                    {feature.sub_features.map((sub_feature, sub_feature_index) => {
                                        return (
                                            <div className='detailsgroup__subgroup__item' key={index + sub_feature_index}>
                                                <CHECKBOX 
                                                    name={sub_feature.feature}
                                                    checked={var_organization[sub_feature.feature] === 'YES'}
                                                    onChange={() => onChange(sub_feature.feature)} />
                                                <div className='detailsgroup__subgroup__item__labeltwoline'>
                                                    <div className='text--sm-medium'>{sub_feature.name}</div>
                                                    <div className='text--sm-regular'>{sub_feature.info}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                            </React.Fragment>)
                    })}
                </Form>

                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>Continue to step 3</Button>
                </div>
            </>
        );
    }
};
