import React, { useState, useEffect } from 'react';
import { Button, Form, Message } from 'semantic-ui-react';
import API from '../../../../libs/api-lib';
import Icon from '../../../../components/cmp_icon';
import form_helper from '../../../../libs/form-lib';
import datelib from '../../../../libs/date-lib';
import { FORM_INPUT, FORM_SELECT  } from '../../../../components/cmp_form/cmp_form';



export default function CRD_SUPERADMIN({ var_organization, set_organization, status, onClick_nextstep }) {

    //  variable declarations ------------------------------------------------------------------------------------------

    const [ var_errors, set_errors ] = useState([]);
    /** @type {ReturnType<typeof useState<Array<{key: String, value: String, text: String}>>>} */
    const [ var_language_options, set_language_options ] = useState([]);

    //  event listeners ------------------------------------------------------------------------------------------------

    useEffect(() => {
        populate_language_options();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        set_errors([]);
    }, [status]);



    //  functions ------------------------------------------------------------------------------------------------------
    async function populate_language_options() {
        try {
            let result = await API_get_languages();
            let transformed = result.filter(item => item.status === 'ACTIVE');
            transformed.forEach(item => { 
                item.key = item.name;
                item.value = item.name; 
                item.text = item.display_name 
            });
            set_language_options(transformed);
        } catch (e) {
            console.error(e);
        }
    }


    //  API calls ------------------------------------------------------------------------------------------------------
    /**
     * @returns {Promise<Array<{id: String, name: String, display_name: String, status: 'ACTIVE'|'INACTIVE'}>>}
     */
    function API_get_languages() {
        return API.get('translation', '/get-languages');
    }


    //  event functions ------------------------------------------------------------------------------------------------

    function onChange_input(event, { name, value }) {
        if (name === 'phone' && value !== '' && !(/^\d+$/.test(value))) {
            return
        }

        let organization = {...var_organization, admin: {...var_organization.admin}};
        organization.admin[name] = value;
        set_organization(organization);
        if (var_errors.length > 0) {
            const errors = var_errors.filter(error => error.property !== name)
            set_errors(errors);
        }
    }

    function onClick_next() {
        let organization = {...var_organization, admin: {...var_organization.admin}};
        let errors = [];
        // validation
        if (!organization.admin.firstname || organization.admin.firstname.trim().length === 0) {
            errors.push({property: 'firstname', description: 'First name is required.'});
        }
        if (!organization.admin.lastname || organization.admin.lastname.trim().length === 0) {
            errors.push({property: 'lastname', description: 'Last name is required.'});
        }
        if (organization.admin.phone && !(/[0-9]{10}/.test(organization.admin.phone))) {
            errors.push({property: 'phone', description: 'Please provide a valid phone number.'});
        }
        if (!organization.admin.email || organization.admin.email.trim().length === 0) {
            errors.push({property: 'email', description: 'Email address is required.'});
        } else if (!form_helper.validate_email_address(organization.admin.email)) {
            errors.push({property: 'email', description: 'Please provide a valid email address.'});
        }
        if (!organization.admin.email_language) {
            errors.push({property: 'email_language', description: 'Please select the language for the email.'});
        }
        set_errors(errors);
        if (errors.length > 0) return;

        // cleanse properties
        form_helper.cleanse_string_property(organization.admin, 'firstname');
        form_helper.cleanse_string_property(organization.admin, 'lastname');
        form_helper.cleanse_string_property(organization.admin, 'phone');
        form_helper.cleanse_string_property(organization.admin, 'email');
        organization.admin.email = organization.admin.email.toLowerCase();
        organization.admin.sendemail = 'YES';
        organization.admin.tz = datelib.timezone;
        set_organization(organization);
        onClick_nextstep();
    }



    // RENDER APP ======================================================================================================

    return (
        <>
            {status === 'COMPLETE' || status === 'COMPLETE-PENDING'
                ?
                <div className='card__complete'>
                    <div>{var_organization.admin.firstname} {var_organization.admin.lastname}</div>
                    {var_organization.admin.phone && <div>{var_organization.admin.phone}</div>}
                    <div>{var_organization.admin.email}</div>
                </div>
                :
                <div></div>
            }
            {status === 'CURRENT' && render_form()}
        </>
    );

    function render_form() {
        return (
            <>
                {var_errors.length > 0 &&
                <Message error
                         icon={<Icon name='error' className='icon' />}
                         header={'Required fields are empty or contain invalid data.'}
                />
                }
                <Form>

                    <FORM_INPUT
                        property='firstname'
                        label='First name'
                        value={var_organization.admin.firstname}
                        onChange={onChange_input}
                        placeholder='First name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='lastname'
                        label='Last name'
                        value={var_organization.admin.lastname}
                        onChange={onChange_input}
                        placeholder='Last name'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='phone'
                        label='Phone number'
                        value={var_organization.admin.phone}
                        onChange={onChange_input}
                        placeholder='(xxx) xxx-xxxx'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_INPUT
                        property='email'
                        label='Email address'
                        value={var_organization.admin.email}
                        onChange={onChange_input}
                        placeholder='email@example.com'
                        maxLength={100}
                        disabled={false}
                        errors={var_errors}
                    />

                    <FORM_SELECT
                        property='email_language'
                        label='Email language'
                        description='Choose the language for the invite email.'
                        value={var_organization.admin.email_language}
                        onChange={onChange_input}
                        placeholder='Select'
                        options={var_language_options}
                        errors={var_errors}
                    />

                </Form>
                <div className='card__footer'>
                    <Button className='primary' onClick={onClick_next}>
                        {var_organization.assignments === 'YES' ? 'Continue to step 4' : 'Save organization'}
                    </Button>
                </div>
            </>
        );
    }

};
